export const NavbarItems = [
    {
        title: "ilk11",
        url: "ilk11.futbol",
        cName: "nav-links"
    },
    {
        title: "Maçlar",
        url: "ilk11.futbol",
        cName: "nav-links"
    },
    {
        title: "Halısahalar",
        url: "ilk11.futbol",
        cName: "nav-links"
    },
    {
        title: "Profil",
        url: "ilk11.futbol",
        cName: "nav-links"
    },
    {
        title: "Üye Ol",
        url: "ilk11.futbol",
        cName: "nav-links-mobile"
    },
]
